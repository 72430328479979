@import "styles/theme.less";
@import "styles/color-tokens.generated.less";

@default-size: 16px;
.SkedIcon {
  width: @default-size;
  height: @default-size;
  line-height: @default-size;
  vertical-align: middle;

  @-moz-document url-prefix() {
    line-height: 1.6;
  }

  svg {
    width: inherit !important;
    height: inherit !important;
  }
}

.SkedIcon_color_default {
  > g,
  path {
    fill: @colors-black;
  }
}
.SkedIcon_size_12 {
  width: 12px;
  height: 12px;
  font-size: 12px;
}
.SkedIcon_size_14 {
  width: 14px;
  height: 14px;
  font-size: 14px;
}
.SkedIcon_size_16 {
  width: 16px;
  height: 16px;
  font-size: 16px;
}

.SkedIcon_size_18 {
  width: 18px;
  height: 18px;
  font-size: 18px;
}

.SkedIcon_size_20 {
  width: 20px;
  height: 20px;
  font-size: 20px;
}

.SkedIcon_size_24 {
  width: 24px;
  height: 24px;
  font-size: 24px;
}

.SkedIcon_size_26 {
  width: 26px;
  height: 26px;
  font-size: 26px;
}
.SkedIcon_size_30 {
  width: 30px;
  height: 30px;
  font-size: 30px;
}

.SkedIcon_color_inherit {
  color: inherit;

  > g,
  path {
    fill: currentColor;
  }
}

.SkedIcon_color_info {
  color: @colors-primary500;

  > g,
  path {
    fill: @colors-primary500;
  }
}

.SkedIcon_color_success {
  color: @colors-success500;

  > g,
  path {
    fill: @colors-success500;
  }
}

.SkedIcon_color_error {
  color: @colors-error500;

  > g,
  path {
    fill: @colors-error500;
  }
}

.SkedIcon_color_warning {
  color: @colors-warning600;

  > g,
  path {
    fill: @colors-warning600;
  }
}

.SkedIcon_color_purple {
  color: @colors-primary500;
  > g,
  path {
    fill: @colors-primary500;
  }
}

.SkedIcon_color_purple:hover {
  color: @colors-primary600;
  > g,
  path {
    fill: @colors-primary600;
  }
}

.SkedIcon_color_red {
  color: @colors-error500;
  > g,
  path {
    fill: @colors-error500;
  }
}

.SkedIcon_color_red:hover {
  color: @colors-error600;
  > g,
  path {
    fill: @colors-error600;
  }
}

.SkedIcon_color_yellow {
  color: @colors-warning500;
  > g,
  path {
    fill: @colors-warning500;
  }
}

.SkedIcon_color_white {
  color: @colors-white;
  > g,
  path {
    fill: @colors-white;
  }
}

.SkedIcon_color_gray {
  color: @colors-neutral400;
  > g,
  path {
    fill: @colors-neutral400;
  }
}

@font-root-path: '/assets/resources/fonts';