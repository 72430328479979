@import "styles/theme.less";

.PostLink {
  margin-right: 5px;

  &-Icon {
    position: relative;
    color: @sked-purple;
    g,
    path {
      fill: @sked-purple;
    }
  }

  &-Text {
  }
}

.PostLink-NotifyCount {
  position: absolute;
  background-color: @sked-alert;
  top: -15px;
  left: 11px;
  color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  font-size: 10px;
  padding: 0px 2px;
}

.PostLink_alert {
  color: @sked-alert;
  g,
  path {
    fill: @sked-alert;
  }
}

.PostLink_alert:hover {
  color: @sked-alert-hover;
  g,
  path {
    fill: @sked-alert-hover;
  }
}

.icon-retry {
  width: 20px;
  height: 20px;

  g,
  path {
    fill: @sked-purple;
  }
}

@font-root-path: '/assets/resources/fonts';