@import "styles/color-tokens.generated.less";

// Colors
@sked-purple: @colors-primary500;
@sked-icon-purple: @colors-primary800;

@sked-grey: @colors-neutral300;
@sked-medium-grey: @colors-neutral100;
@sked-light-grey: @colors-neutral50;
@sked-dark-grey: @colors-neutral400;
@sked-alert: @colors-error400;
@sked-alert-hover: @colors-error500;
@sked-white: @colors-white;

// Tables
@table-header-color: @colors-neutral200;
@table-other-row-color: @colors-neutral50;

// Less used Colors
@sked-pink: @colors-error200;
@sked-queued: @colors-neutral600;
@sked-scheduled: @colors-success500;

@sked-grey-text: @colors-neutral400;

// Spacing / Sizing
@avatar-size-default: 50px;
@avatar-size-small: 24px;
@avatar-size-medium: 40px;

@font-weight-extra-light: 300;
@font-weight-medium: 400;
@font-weight-bold: 500;
@font-weight-black: 800;

// Debug helpers
.blue {
  border: 1px solid @colors-primary500;
}

.pink {
  border: 1px solid @colors-cosmo;
}

.green {
  border: 1px solid @colors-success500;
}

.black {
  border: 1px solid @colors-black;
}
