@import "styles/theme.less";
@card-margin: 10px;
@num-cards: 5;
.FeedMedia-Card {
  margin: @card-margin;
  position: relative;
  width: calc((100% - (@card-margin * @num-cards * 2)) / @num-cards);

  img {
    object-fit: contain;
    object-position: center;
    max-width: 100%;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }
}

.FeedMedia-Card:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.FeedMedia-Asset {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.FeedMedia-Asset_selected {
  border: 6px solid @sked-purple;
}

.FeedMedia-Hover {
  min-height: 0;
  max-height: 0;
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.FeedMedia-Card:hover &.FeedMedia-Hover {
  opacity: 1;
  display: block;
  position: absolute;
  max-height: 30%;
  min-height: 30%;
  height: 30%;
  bottom: 0;
  width: 100%;
  background: white;
  opacity: 80%;
}

@font-root-path: '/assets/resources/fonts';