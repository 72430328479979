.DayPicker-Caption {
  color: #868686;
}
.DayPicker-Day--outside {
  color: #868686;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

@font-root-path: '/assets/resources/fonts';