@import "styles/theme.less";

.AccountAvatar {
  width: @avatar-size-default;
  height: @avatar-size-default;
  position: relative;

  &.AccountAvatar_size_medium {
    width: @avatar-size-medium;
    height: @avatar-size-medium;
    min-width: @avatar-size-medium;
    min-height: @avatar-size-medium;
  }

  &.AccountAvatar_size_small {
    width: @avatar-size-small;
    height: @avatar-size-small;
    min-width: @avatar-size-small;
    min-height: @avatar-size-small;
  }

  img {
    width: 100%;
    border-radius: 50%;
  }

  i {
    margin-left: 0;
    margin-right: 0;
    vertical-align: bottom;
    position: absolute;
    bottom: 0px;
    right: 0px;
    left: auto;
    z-index: 3;
    width: 15px;
    height: 15px;
    min-width: 15px;
    min-height: 15px;
  }
}

@font-root-path: '/assets/resources/fonts';