@import "styles/theme.less";

.PostLinkToPost {
  display: inline-block;
  margin-right: 5px;
  color: @sked-purple;
}

.PostLinkToPost a:hover {
  text-decoration: none;
}

@font-root-path: '/assets/resources/fonts';