@import "styles/theme.less";

.PostMedia {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  &-TextOnly {
    background-color: @sked-medium-grey;
    border: 1px solid #eee;
    color: @sked-dark-grey;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 200px;
    width: 100%;

    .PostMedia-TextOnlyText {
      font-weight: 700;
    }

    .PostMedia-TextOnlySvg {
      svg {
        width: 65px;
        height: 65px;

        g {
          fill: @sked-dark-grey;
        }
      }
    }
  }

  .PostMedia-MediaTypeIcon {
    position: absolute;
    top: 8px;
    right: 8px;
    color: @sked-white;
    z-index: 2;

    svg path,
    svg g {
      fill: #fff;
    }
    // adjust spacing for video icon
    &.icon-post-video {
      right: 16px;
    }
  }

  .PostMedia-PostStatusTag {
    z-index: 2;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;

    .PostMedia-MediaStatus {
      height: 35px;
      min-width: 35px;
      text-align: left;
      line-height: 35px;

      .PostMedia-MediaScheduled,
      .PostMedia-MediaQueued {
        padding: 0px 5px;
        font-weight: 500;
        transition: width 2s;
        cursor: default;
        color: @sked-white;

        .PostMedia-StatusFirstLetter,
        .PostMedia-StatusFullText {
          height: 100%;
          width: 100%;
          padding-left: 4px;
        }

        &:hover {
          .PostMedia-StatusFirstLetter {
            display: none;
          }

          .PostMedia-StatusFullText {
            display: inline-block;
            transition: width 2s;
          }
        }

        .PostMedia-StatusFullText {
          display: none;
        }
      }

      .PostMedia-MediaScheduled {
        background: @sked-scheduled;
      }

      .PostMedia-MediaQueued {
        background: @sked-queued;
      }
    }
  }

  &-Middle {
    overflow: hidden;
    position: relative;

    &:hover .PostMedia-CarouselButton {
      display: inline-block;
    }

    .PostMedia-CarouselButton:disabled {
      background: @sked-dark-grey !important;
      opacity: 0.5;
      cursor: not-allowed;

      .PostMedia-CarouselOverlay {
        background: @sked-dark-grey;
      }

      .PostMedia-Chevron {
        cursor: not-allowed;
      }
    }

    .PostMedia-CarouselButton {
      display: none;
      position: absolute;
      width: 30%;
      height: 100%;
      font-size: 12px;
      cursor: pointer;
      border-radius: 0;
      z-index: 1;
      border: none;
      padding: 0px;
      background: none;

      .PostMedia-CarouselOverlay {
        opacity: 0.5;
        background: @sked-grey;
        height: 100%;
        width: 100%;
      }

      &.PostMedia-CarouselButton_left {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 30%;
        min-width: 50px;
        height: 100%;
      }

      &.PostMedia-CarouselButton_right {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 30%;
        min-width: 50px;
        height: 100%;
      }
    }

    .PostMedia-Chevron {
      position: absolute;
      background: none;
      top: 50%;
      left: calc(50% - 12px);
    }
  }

  .PostMedia-Bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .PostMedia-Dots:hover a {
      text-decoration: none;
    }

    .PostMedia-Dots a {
      margin-right: 5px;
      font-size: 20px;
    }

    .PostMedia-Dots {
      margin-top: 20px;
    }

    .PostMedia-AddToCarousel {
      margin: 20px 0px;
    }
  }

  img {
    object-fit: contain;
    object-position: center;
    max-width: 100%;
    max-height: 300px;
  }
}

@font-root-path: '/assets/resources/fonts';