@import "styles/theme.less";

.ListPostDetails {
  @caption-line-height: 20px;
  &-Caption {
    float: none;
    position: inherit;
    line-height: 20px;
    word-break: break-word;
    white-space: pre-line;
  }

  &_showLess {
    overflow: hidden;
    max-height: calc(@caption-line-height * 5);
  }

  &-FirstComment {
    word-break: break-word;
    white-space: pre-line;
  }

  &-RedirectUrl {
    word-break: break-all;
  }
}

@font-root-path: '/assets/resources/fonts';