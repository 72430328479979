@import "styles/theme.less";

.SkedPagination {
  .pagination > .active > a {
    background-color: @sked-purple;
    border-color: @sked-purple;
    color: #fff;
  }

  .pagination > li > a {
    color: @sked-purple;
  }
}

@font-root-path: '/assets/resources/fonts';