@import "styles/theme.less";

.PostActions {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  white-space: nowrap;
}

.PostActions-Ul {
  & ul {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

  li {
    margin-bottom: 1.5rem;

    a {
      text-decoration: none;
      font-size: 1.4rem;

      .icon {
        font-size: 15px;
        margin-right: 1rem;
        // for SVG icons
        width: 18px;
        height: 18px;
        display: inline-block;
      }

      .text {
        font-size: 1.4rem;
      }

      .text:hover {
        text-decoration: underline;
      }
    }
  }

  li:last-child {
    margin-bottom: 0px;
  }
}

@font-root-path: '/assets/resources/fonts';