.SkedFormControl {
}

.SkedFormControl.form-control,
.SkedFormControl {
  height: 32px;
  display: inline-block;
  line-height: 20px !important;
  font-size: 14px !important;
  font-weight: 300 !important;
}

@font-root-path: '/assets/resources/fonts';