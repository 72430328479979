@import "styles/theme.less";
@import "styles/color-tokens.generated.less";

.SkedButton {
  position: relative;
  .SkedButton-Btn {
    outline: 0;
    display: inline-block;

    &.SkedButton-Size_small {
      font-weight: 300;
      height: 34px;
      min-width: 40px;
      line-height: 34px;
      font-size: 12px;
      border-radius: 4px;
      padding: 0 10px;
    }

    &.SkedButton-Size_medium {
      height: 52px;
      min-width: 40px;
      line-height: 52px;
      font-size: 16px;
      font-weight: 700;
      border-radius: 5px;
    }

    &.SkedButton-Size_large {
      font-weight: 800;
    }
    &:disabled {
      // background: 0 0 !important;
      opacity: 0.5;
    }

    .SkedButton-SelectedCount {
      width: 24px;
      height: 24px;
      line-height: 24px;
      border-radius: 50%;
      text-align: center;
      display: inline-block;
      color: #fff;
      font-size: 14px;
      vertical-align: middle;
      margin-top: -3px;
      margin-right: 0.6rem;

      &.SkedButton-BulkQueue {
        background: @colors-neutral800;
      }

      &.SkedButton-BulkDraft {
        background: @colors-warning500;
      }

      &.SkedButton-BulkTrash {
        background: @colors-error600;
      }
    }
  }

  .SkedButton_alertOutline {
    color: @colors-error500;
    background: 0 0;
    border: 1px solid @colors-error500;
    transition: all 80ms linear;
    > g,
    path {
      fill: @colors-error500;
    }
  }

  .SkedButton_alertOutline:hover {
    color: #fff;
    background: red;
    > g,
    path {
      fill: #fff;
    }
  }

  .SkedButton_alertFilled {
    color: @colors-white;
    background-color: @colors-error500;
    transition: all 80ms linear;
    > g,
    path {
      fill: @colors-error500;
    }
  }

  .SkedButton_alertFilled:hover {
    color: @colors-white;
    background: @colors-error600;
    > g,
    path {
      fill: @colors-error600;
    }
  }
  .SkedButton_ghost {
    color: @colors-white;
    box-shadow: 0px 1px 4px fade(@colors-black, 50%);
    background: 0 0;
    border: 1px solid @colors-white;
    transition: all 80ms linear;
  }
  .SkedButton_ghost:hover {
    color: @colors-neutral900;
    box-shadow: 0px 1px 4px fade(@colors-black, 50%);
    background: @colors-white;
    border: 1px solid @colors-white;
    transition: all 80ms linear;
  }

  .SkedButton_simple {
    color: @colors-black;
    background: @colors-white;
    border: 1px solid @colors-neutral300;
    transition: all 80ms linear;
  }
  .SkedButton_simple:hover {
    color: @colors-black;
    box-shadow: 0px 1px 4px fade(@colors-black, 50%);
    background: @colors-neutral50;
    border: 1px solid @colors-neutral300;
    transition: all 80ms linear;
  }

  .SkedButton_primaryOutline {
    color: @sked-purple;
    background: 0 0;
    border: 1px solid @sked-purple;
    transition: all 80ms linear;
  }

  .SkedButton_primaryOutline:hover {
    color: #fff;
    background: @sked-purple;
  }
}

// Size

@font-root-path: '/assets/resources/fonts';