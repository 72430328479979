// Just makes everything a bit smaller as we're editing
// in a small space
.SkedDataTable {
  .control-label {
    font-size: 12px;
    font-weight: 500;
  }
  .form-control {
    padding: 0 1rem;
    font-size: 12px;
    line-height: 36px;
  }
}

@font-root-path: '/assets/resources/fonts';