@tag-color: #e7e7e7;
.PostTag {
  background-color: @tag-color;
  width: min-content;
  height: 28px;
  padding: 2px;
  padding-right: 10px;
  margin-left: 15px;
  margin-right: 5px;
  display: inline-block;
  border-radius: 0px 4px 4px 0px;
  display: flex;
  align-items: center;
  position: relative;

  &-Point {
    position: absolute;
    top: 0px;
    left: -15px;
    width: 0;
    height: 0;
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent;
    border-right: 15px solid @tag-color;
  }

  &-Icon {
    margin-right: 5px;
    font-size: 14px;
    color: #888;
  }

  &-Title {
    white-space: nowrap;
    font-size: 14px;
    font-weight: 400;
  }
}

@font-root-path: '/assets/resources/fonts';