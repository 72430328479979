@import "styles/theme.less";
@card-margin: 10px;
@num-cards: 5;
.GridMediaCard-Card {
  margin: @card-margin;
  position: relative;
  width: calc((100% - (@card-margin * @num-cards * 2)) / @num-cards);
  background-color: #eee;

  img {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
    object-fit: contain;
    object-position: center;
    max-width: 100%;
    max-height: 100%;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }
  &:hover {
    .GridMediaCard-CheckboxBackground {
      display: inline;
    }
    .GridMediaCard-FavoriteBackground {
      display: inline;
    }
  }
}

.GridMediaCard-Card:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.GridMediaCard-Asset {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  flex-direction: column;
}
.GridMediaCard-FavoriteBackground {
  display: none;
  position: absolute;
  left: 8%;
  right: 80%;
  top: 8%;
  bottom: 80%;
  opacity: 0.8;
  background: none;
  &:hover {
    opacity: 1;
    .favorited {
      display: block;
      svg {
      }
    }
    .favorite {
      display: block;
      svg {
        path {
          stroke: @sked-dark-grey;
        }
      }
    }
  }
}
.GridMediaCard-CheckboxBackground {
  display: none;
  position: absolute;
  left: 80%;
  right: 8%;
  top: 8%;
  bottom: 80%;
  background: #ffffff;
  border-radius: 2px;
  opacity: 0.5;
  &:hover {
    .checked {
      display: block;
      svg {
        path {
          stroke: @sked-dark-grey;
        }
      }
    }
  }
  &_selecting {
    display: block;
  }
  .checked {
    display: none;
    height: 100%;
    width: 100%;

    svg {
      margin: auto;
      height: 100%;
      width: 90%;
      display: block;
    }
  }
}
.GridMediaCard-Asset_selected {
  border: 6px solid @sked-purple;
  // show item
  .GridMediaCard-CheckboxBackground {
    display: inline;
    opacity: 1 !important;
    .checked {
      display: block;
      svg {
        path {
          stroke: @sked-purple !important;
        }
      }
    }
  }
}

.GridMediaCard-Hover {
  display: none;
  position: absolute;
  max-height: 30%;
  min-height: 30%;
  height: 30%;
  bottom: 0;
  width: 100%;
  background: white;
  opacity: 90%;
}

.GridMediaCard-Card:hover &.GridMediaCard-Hover {
  display: block;
}

@font-root-path: '/assets/resources/fonts';